/**
 * ハンバーガー開閉に必要なセレクターのコンストラクタ
 */
class Selector {
  constructor({ ...targets }) {
    this.body = targets.body;
    this.outer = targets.outer;
    this.button = targets.button;
  }
}

/**
 * ハンバーガー開閉に必要なセレクターを取得
 * @returns { object }
 */
function target() {
  const body = document.querySelector('body');
  const outer = document.querySelector('.js-headerMenu');
  const button = document.querySelector('.js-headerButton');
  return { body, outer, button };
}

/**
 * 初期値
 */
const selectors = new Selector({ ...target() });

/**
 * ハンバーガー開閉の制御
 */
export function toggleHamburger() {
  selectors.button.addEventListener('click', () => {
    const isOpen = selectors.button.classList.contains('is-active');

    if (isOpen) {
      close();
    } else {
      open();
    }
  });
}

/**
 * ハンバーガー下層開閉の制御
 */
export function toggleHamburgerInner() {
  const buttonInners = Array.prototype.slice.call(selectors.buttonInners, 0);

  buttonInners.forEach((buttonInner) => {
    buttonInner.addEventListener('click', () => {
      const headerButtonInner = buttonInner.nextElementSibling;
      const isOpen = headerButtonInner.classList.contains('is-active');

      if (isOpen) {
        headerButtonInner.classList.remove('is-active');
      } else {
        headerButtonInner.classList.add('is-active');
      }
    });
  });
}

/**
 * ハンバーガーを閉じる
 */
function close() {
  // bodyの固定
  selectors.body.classList.remove('is-active');

  // hamburger
  selectors.outer.classList.remove('is-active');
  selectors.button.classList.remove('is-active');
}

/**
 * ハンバーガーを開く
 */
function open() {
  // bodyの固定
  selectors.body.classList.add('is-active');

  // hamburger
  selectors.outer.classList.add('is-active');
  selectors.button.classList.add('is-active');
}
