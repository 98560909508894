export function common() {

  // タブレット表示
  $(function(){
    var ua = navigator.userAgent;
    var viewport = document.querySelector("meta[name=viewport]");
    if((ua.indexOf('iPhone') > 0) || ua.indexOf('iPod') > 0 || (ua.indexOf('Android') > 0 && ua.indexOf('Mobile') > 0)){
        viewport.setAttribute("content", "width=device-width,initial-scale=1");
    } else {
        viewport.setAttribute("content", "width=1230");
    }
  });

  // スマホtel link
  if (navigator.userAgent.match(/(iPhone|iPad|iPod|Android)/)) {
    jQuery(function($) {
        $('.tel').each(function() {
            var str = $(this).html();
            if ($(this).children().is('img')) {
                $(this).html($('<a>').attr('href', 'tel:' + $(this).children().attr('alt').replace(/-/g, '')).append(str + '</a>'));
            } else {
                $(this).html($('<a>').attr('href', 'tel:' + $(this).text().replace(/-/g, '')).append(str + '</a>'));
            }
        });
    });
  }

  //正確なvhの取得
  /*-
  使い方: calc(var(--vh, 1vh) * 100);
  -*/
  function setHeight() {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }
  setHeight();//初期化
  window.addEventListener('resize', setHeight);// 再計算



  //ブレイクポイント画像切り替え
  var $elem = $('.sp_img');
  var sp = '_sp.';
  var pc = '_pc.';
  var replaceWidth = 767; //ブレイクポイント指定

  function imageSwitch() {
    var windowWidth = parseInt($(window).width()); //ウィンドウサイズ取得
    $elem.each(function () {
        var $this = $(this);
        if (windowWidth >= replaceWidth) {
            $this.attr('src', $this.attr('src').replace(sp, pc));
        } else {
            $this.attr('src', $this.attr('src').replace(pc, sp));
        }
    });
  }
  imageSwitch();

  // リサイズの実行タイミング設定
  var delayStart;
  var delayTime = 200;
  $(window).on('resize', function () {
  clearTimeout(delayStart);
    delayStart = setTimeout(function () {
      imageSwitch();
    }, delayTime);
  });


  //ヘッダーの高さに連動
  function headerHeightSwitch() {
    var headerHeight = jQuery('.l-header').outerHeight(); //ウィンドウサイズ取得
    //jQuery('.l-main_inner').css({'padding-top': headerHeight});
    jQuery('.anchor').css({'padding-top': headerHeight});
    jQuery('.anchor').css({'margin-top': -headerHeight});
  }
  headerHeightSwitch();
  jQuery(window).on('resize', function () {
    headerHeightSwitch();
  });


  //navi PC
  if (window.matchMedia('(min-width: 1100px)').matches) {
    jQuery(function ($) {
        $('.js-headerMenu .l-header_menuItem').hover(function() {
            $(this).find('ul').stop().slideDown();
        }, function() {
            $(this).find('ul').stop().slideUp();
        });
    })
  }


  //js-scroll
  var wH = $(window).height();
  var EffectH = wH/6*1;
  jQuery(window).on('scroll load', function() {
    var scTop = $(this).scrollTop();
    var scBottom = scTop + $(this).height();
    var effectPos = scBottom - EffectH;
    jQuery('.js-scroll, .js-scroll-delay').each( function() {
      var thisPos = $(this).offset().top;
      if ( thisPos < effectPos ) {
        $.when(
          jQuery(this).addClass("show")
        ).done(function() {
          jQuery(this).delay(250).queue(function(){
              jQuery(this).addClass("done")
          })
        });
      }
    });
  });


  //ページトップ
  var pageTop = $('#pageTop');
  pageTop.hide();
  $(window).scroll(function () {
    if ($(this).scrollTop() > 650) {
      pageTop.fadeIn();
    } else {
      pageTop.fadeOut();
    }
  });
  pageTop.click(function () {
    $('body,html').animate({
      scrollTop: 0
    }, 550);
    return false;
  });

  //スムーススクロール
  $('a[href^="#"]').click(function () {
    var speed = 550;
    var href = $(this).attr("href");
    //var headerHight = $('.l-header_inner').outerHeight();
    var target = $(href == "#" || href == "" ? 'html' : href);
    var position = target.offset().top;
    $("html, body").animate({ scrollTop: position }, speed, "swing");
    return false;
  });


}
